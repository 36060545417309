/* eslint-disable react/no-unknown-property */
import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"
import { css } from "@emotion/react"
import Link from "components/Link"
import styled from "@emotion/styled"
import { theme } from "common/colorScheme"
import { useCallback, useMemo, useState } from "react"
import Container from "../container"
import Logo from "../../images/latana-logo.svg"
import LogoWhite from "../../images/latana-logo-white.svg"
import { mediaBreakpoint, BreakPoints } from "../../settings/breakpoints"
import HeaderLink, { MenuIndicator } from "./HeaderLink"
import FlyoutMenu from "./FlyoutMenu"
import MobileMenu from "./MobileMenu"
import { BurgerMenuIcon } from "../icons"
import { ButtonBase } from "../button"
import { HeaderEntry, HeaderVariant } from "./types"

export const query = graphql`
	fragment HeaderLinkPage on ContentfulPage {
		__typename
		id
		slug
	}
	fragment HeaderLinkUrl on ContentfulLinksUrl {
		__typename
		id
		url
	}
	fragment HeaderLink on ContentfulLinks {
		__typename
		id
		text
		reference: link {
			...HeaderLinkPage
			...HeaderLinkUrl
		}
	}
	query Header {
		allContentfulHeaderEntry(filter: { node_locale: { eq: "en-US" } }) {
			edges {
				node {
					id
					toRight
					placement
					order
					link {
						... on ContentfulButton {
							...Button
						}
						... on ContentfulLinks {
							...HeaderLink
						}
					}
					flyoutLinks {
						id
						text
						description {
							description
						}
						link {
							...HeaderLinkPage
							...HeaderLinkUrl
						}
						featured {
							__typename
							id
							title {
								title
							}
							category
							description {
								description
							}
							heroImage {
								...Media400
							}
							referenceImage {
								...Media600
							}
							slug
						}
					}
					highlightedLinks {
						id
						text
						description {
						  description
						}
						link {
						  ...HeaderLinkPage
						  ...HeaderLinkUrl
						}
						featured {
						  __typename
						  id
						  title {
							title
						  }
						  category
						  description {
							description
						  }
						  heroImage {
							...Media400
						  }
						  referenceImage {
							...Media600
						  }
						  slug
						}
					}
					featuredType
					featuredBlogPost {
						__typename
						id
						title
						heroImage {
							...Media400
						}
						slug
					}
					showCategory
					itemsPerColumn
					totalColumns
				}
			}
		}
	}
`

const mainContainer = css`
	position: relative;
	height: 70px;
	color: #181818;
	z-index: 99;

	&:not(.transparent) {
		background: rgba(255, 255, 255, 1);

		${MenuIndicator} {
			background-color: black;
		}
	}
`

const whiteLogo = css`
	img[data-variant="default"] {
		opacity: 0;
	}
	img[data-variant="transparent"] {
		opacity: 1;
	}
`

const blackLogo = css`
	img[data-variant="default"] {
		opacity: 1;
	}
	img[data-variant="transparent"] {
		opacity: 0;
	}
`

const primaryNavContainer = css`
	height: 70px;
	width: 100%;
	display: flex;
	position: fixed;
	${blackLogo};

	&.scrolled {
		background-color: rgba(255, 255, 255, 1);
		button {
			filter: invert(0);
		}
		${blackLogo};
		${MenuIndicator} {
			background-color: black;
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		position: fixed;

		&.scrolled {
			background-color: transparent;

			&:not(.transparent) {
				background-color: rgba(255, 255, 255, 1);
			}
		}

		&.fixed {
			position: fixed;
			top: 0;

			${ButtonBase}[data-variant="primary"] {
				&:not(:hover) {
					color: #000;
					border: 1px solid #000 !important;
					background-color: transparent;
				}

				&:hover {
					span {
						color: #fff;
					}
				}
			}
		}
	}
`

const containerStyles = css`
	display: flex;
	justify-content: center;
	min-height: 56px;
`

const transparentStyles = css`
	background: rgba(255, 255, 255, 0);
	border-color: rgba(221, 225, 230, 0);
`

const transparentWhiteStyles = css`
	color: #fff;
	${whiteLogo};
	${ButtonBase}[data-variant="primary"] {
		&:not(:hover) {
			background-color: transparent;
			border: 1px solid #fff;
		}
	}

	${mediaBreakpoint(BreakPoints.LG)} {
		${ButtonBase}[data-variant="hollow"] {
		}
		${ButtonBase}[data-variant="primary"] {
			&:not(:hover) {
			}
		}
	}
	&:not(.scrolled),
	&:not(.fixed) {
		${ButtonBase}[data-variant="hollow"] {
			color: #fff;
		}
	}

	&.scrolled {
		${mediaBreakpoint(BreakPoints.LG)} {
			background-color: transparent;
			${whiteLogo}
		}
	}

	&.fixed {
		background-color: rgba(255, 255, 255, 1);
		${blackLogo};
		color: #000;

		span {
			color: #000;
		}

		i {
			filter: brightness(0) invert(0);
		}
	}
`

const transparentBlackStyles = css`
	${MenuIndicator} {
		background-color: black;
	}

	${ButtonBase}[data-variant="primary"] {
		color: #fff;
		border: 1px solid #000;
	}

	&.fixed {
		background-color: rgba(255, 255, 255, 1);
	}
`

const NavContainer = styled.nav`
	display: none;
	height: 70px;
	${mediaBreakpoint(BreakPoints.LG)} {
		display: flex;
		justify-content: end;
		margin: 0 0 0 24px;
		flex-grow: 1;
	}
	${ButtonBase}[data-variant="hollow"] {
		min-width: 136px;
	}
`

const LogoContainer = styled.div`
	flex-basis: 100%;

	${mediaBreakpoint(BreakPoints.SM)} {
		flex-basis: auto;
		min-width: 140px;
	}
`

const LogoWrapper = styled.div`
	position: relative;
	height: 42px;
	width: 138px;
`

const LogoStyles = styled.img`
	position: absolute !important;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	margin: 0;
	vertical-align: middle;
	position: relative;
	transition: opacity 0.15s ease-in-out;
`

const NavLeft = styled.div`
	display: flex;
	margin: ${theme.spacing(0, 1.5, 0, 0)};
`

const NavRight = styled.div`
	display: flex;
	margin: ${theme.spacing(0, 0, 0, 1.5)};
`

const InnerContainer = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	${mediaBreakpoint(BreakPoints.LG)} {
		justify-content: initial;
	}
`

const mobileMenuBtnStyles = css`
	display: block;
	padding: 0;
	width: 28px;
	height: 28px;
	border: none;
	background: url("${BurgerMenuIcon}") no-repeat center center;
	background-size: cover;
	${mediaBreakpoint(BreakPoints.LG)} {
		display: none;
	}

	&.white {
		filter: invert(1);
	}
`
const LinkContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`

export interface HeaderConfig {
	variant: HeaderVariant
}

interface Props {
	siteTitle: string
	config?: HeaderConfig
}

const Header: React.FC<Props> = ({ siteTitle, config }) => {
	const [mobileOpen, setMobileOpen] = useState(false)
	const [fixed, setFixed] = useState(false)
	const [scrolled, setScrolled] = useState(false)
	const [activeMenu, setActiveMenu] = useState<null | string>(null)

	const themeIsWhite = config?.variant === HeaderVariant.TransparentWhite
	const themeIsTransparent =
		config?.variant === HeaderVariant.TransparentWhite || config?.variant === HeaderVariant.TransparentBlack

	const onMobileClose = useCallback(() => {
		setMobileOpen(false)
	}, [])
	const handleMobileOpen = useCallback(() => {
		setMobileOpen(true)
	}, [])

	const { allContentfulHeaderEntry } = useStaticQuery(query)

	const { left, right, mobileExtra } = useMemo(() => {
		const entries = allContentfulHeaderEntry.edges
			.map(({ node }) => node)
			.filter(({ link }) => link)
			.sort(({ order: orderA }, { order: orderB }) => orderA - orderB)

		const leftEntries = entries.filter(({ placement }) => placement === "left")
		const rightEntries = entries.filter(({ placement }) => placement === "right")
		const mobileEntries = entries.filter(({ placement }) => placement === "mobile-bottom")

		return {
			left: leftEntries,
			right: rightEntries,
			mobileExtra: mobileEntries,
		}
	}, [allContentfulHeaderEntry])

	const headerRef = React.useRef<HTMLElement>()

	const transparent = React.useMemo(() => {
		return config && [HeaderVariant.TransparentBlack, HeaderVariant.TransparentWhite].includes(config.variant)
	}, [config])

	React.useEffect(() => {
		if (typeof window !== "undefined") {
			const onScroll = () => {
				setFixed(window.pageYOffset >= 1)
				setScrolled(window.pageYOffset >= 1)
			}

			window.addEventListener("scroll", onScroll)
			onScroll()

			return () => {
				window.removeEventListener("scroll", onScroll)
			}
		}
		return null
	}, [])

	const getTransparentStyles = React.useMemo(() => {
		if (transparent)
			return [transparentStyles, themeIsWhite && !fixed ? transparentWhiteStyles : transparentBlackStyles]
		return []
	}, [transparent, config])

	return (
		<header css={mainContainer} className={themeIsTransparent && "transparent"}>
			<div
				css={[primaryNavContainer, ...getTransparentStyles]}
				className={`${scrolled && "scrolled"} ${fixed && "fixed"} ${themeIsTransparent && "transparent"}`}
				ref={headerRef as React.LegacyRef<HTMLDivElement>}
			>
				<Container css={containerStyles}>
					<InnerContainer>
						<LogoContainer>
							<Link to="https://www.latana.com/">
								<LogoWrapper>
									<LogoStyles
										data-variant="default"
										src={Logo}
										alt={siteTitle}
										title={siteTitle}
										height="42"
										width="138"
									/>
									<LogoStyles
										data-variant="transparent"
										src={LogoWhite}
										alt={siteTitle}
										title={siteTitle}
										height="42"
										width="138"
									/>
								</LogoWrapper>
							</Link>
						</LogoContainer>
						<NavContainer>
							 <NavLeft>
								{left.map((link: HeaderEntry) => {
									const { id, flyoutLinks, featuredBlogPost, featuredType, showCategory, itemsPerColumn, totalColumns, highlightedLinks } = link
									const active = activeMenu === id
									return (
										<LinkContainer
											key={id}
											onMouseEnter={() => setActiveMenu(id)}
											onMouseLeave={() => setActiveMenu(null)}
										>
											<HeaderLink expanded={active} {...link} />
											{flyoutLinks?.length  > 0 && (
												<FlyoutMenu
													active={active}
													links={flyoutLinks}
													featuredBlogPost={featuredBlogPost}
													featuredType={featuredType}
													showCategory={showCategory}
													itemsPerColumn={itemsPerColumn} 
													totalColumns={totalColumns}
													highlightedLinks={highlightedLinks}
												/>
											)}
										</LinkContainer>
									)
								})}
							</NavLeft> 
							<NavRight>
								{right.map((link: HeaderEntry) => {
									const { id } = link
									const active = activeMenu === id
									return (
										<LinkContainer
											key={id}
											onMouseEnter={() => setActiveMenu(id)}
											onMouseLeave={() => setActiveMenu(null)}
										>
											<HeaderLink expanded={active} {...link} />
										</LinkContainer>
									)
								})}
							</NavRight>
						</NavContainer>
						<button
							aria-label="Toggle mobile menu"
							type="button"
							css={mobileMenuBtnStyles}
							onClick={handleMobileOpen}
							className={themeIsWhite || fixed ? "white" : ""}
						/>
					</InnerContainer>
				</Container>
				{mobileOpen ? <MobileMenu onClose={onMobileClose} top={left} bottom={right} extra={mobileExtra} /> : null}
			</div>
		</header>
	)
}

export default React.memo(Header)
